import Typical from 'react-typical';
import QuocImage from '../../img/qv.png';
import { GoUnmute } from 'react-icons/go';
import DownloadButton from '../PagesContent/SpecialButton.js';
import FadeInSection from './FadeInSection';

const Intro = () => {
    return (
    <div className="content-wrapper items-center justify-center center-div md:h-screen md:pt-[70px]" id="intro">

     {/*      <div className='md:top-24 md:right-12 absolute hidden md:block'>
            <img src="https://fontmeme.com/permalink/230222/dca3f92b90a315147e67385e38a3a9dc.png"/>
        </div>

        <div className='absolute md:hidden block top-28 right-6'>
            <img src="https://fontmeme.com/permalink/230222/715b121687c353a5a136c96eaf30f02d.png"/>
        </div>
        */}

        <div className="md:ml-20 md:flex-row md:flex md:mb-16 md:mt-28 mt-16 md:mx-5 ml-1">
            
            <div className="justify-center text-center ml-3 mb-10 md:-mt-10 md:mb-5 md:ml-9 center-div "> 
            <FadeInSection>
                <img alt="profilelogo" src={QuocImage} className="picture-hover w-auto h-auto md:w-[240px] shadow-lg max-w-xs rounded-3xl justify-center text-center center-div super-important-center z-[5]"/>

                {/* <div className="absolute z-[0] w-[15%] h-[15%] top-0 pink__gradient" />
                <div className="absolute z-[1] w-[20%] h-[20%] rounded-full white__gradient bottom-40" />
                <div className="absolute z-[0] w-[20%] h-[15%] left-10 bottom-20 blue__gradient" /> */}
            </FadeInSection>
            </div>
            
            
            <div className="md:ml-40 ">
                
                {/* <div className="text-xl text-gray-800">Hi! I am  */}
                
                <div className="text-xl text-white dark:text-white ml-1 md:ml-0 justify-center text-center md:text-left md:justify-start md:mt-0 flex flex-row">
                    
                    <span className="beauty-text text-[28px]"><strong>Quoc Thinh Vo</strong>
                    </span><Audio/>
                    
                </div>
                
                <div className="text-gray-200 dark:text-accent md:text-xl ml-1 md:ml-0 justify-start text-center md:text-left">
                    
                    <p>
                    {/* I'm a&nbsp;  */}
                    <Typical 
                        steps={[
                            "Software Engineer", 3000,
                            "AI/ML Research Assistant", 3000,
                        ]}
                        wrapper="span"
                        loop={Infinity} />
                    </p>
                    
                </div>
               
                <FadeInSection>
                <div className='mt-7 md:mt-4'>
                {/* <h1>Current Positions:</h1>  */}
                {/* <p>👨‍💻 Software Engineer @ <a href="https://roku.com" target="_blank" rel="noopener noreferrer" className="custom-text-color dark:text-accent  drop-shadow-sm">Roku Inc.</a>, <a href="https://moberganalytics.com" target="_blank" rel="noopener noreferrer" className="custom-text-color dark:text-accent  drop-shadow-sm">Moberg Analytics</a></p> */}
                <p>👨‍💻 Software Engineer @  <a href="https://roku.com" target="_blank" rel="noopener noreferrer" className="custom-text-color dark:text-accent  drop-shadow-sm">Roku Inc.</a></p>

                {/* <p>👨‍💼 Vice President @ <a href="https://hkn.ieee.org/hkn-chapters/all-chapters/beta-alpha-chapter" target="_blank" rel="noopener noreferrer" className="custom-text-color dark:text-accent drop-shadow-sm">Drexel IEEE-HKN</a></p> */}
                <p>👨‍🔬 Research Assistant @ <a href="https://research.coe.drexel.edu/ece/imaple/" target="_blank" rel="noopener noreferrer" className="custom-text-color dark:text-accent  drop-shadow-sm">Drexel iMaPLe Lab</a></p>

                <h1 className='mt-2'>Email:</h1> 
                <p className='md:-mb-4 -mb-3'>📧 contact @ <a href="https://qtvo.dev/" rel="noopener noreferrer" className="custom-text-color dark:text-accent  drop-shadow-sm">qtvo [dot] dev</a></p>
                           
                </div>

                <div className='mt-6 ml-2 md:ml-0'>
                    <div className="divider w-72"></div> 

                    <label className='-mt-5 -mb-5'> 
                    <div className="collapse collapse-plus text-sm -ml-4 -mt-3 ">
                    
                    <input type="checkbox" /> 
                    <div className="collapse-title text-sm w-80 hover:text-yellow-100 hover:animate-pulse -mt-5">
                        Bio
                    </div>
                    <div className="collapse-content -mt-5"> 
                        <p className='max-w-lg mb-5'>
                        A Software Engineer with experience working in the tech, medical device, and pharmaceutical industries. Expertise in full software development life cycle, machine learning, data engineering, and embedded software. Skilled in AWS, GCP, Python, C/C++, JavaScript, React, Node.js, PostgreSQL, Terraform, Docker, and Kubernetes.
                        </p>
                        <p className='max-w-lg mb-5'>
                        Experienced in developing cloud-native software, enhancing systems, developer tools, platform, and infrastructure scalability and reliability, implementing and improving CI/CD best practices. Proven track record of research and publications in Machine Learning for Audio, Acoustic, Speech, and Signal Processing.</p>
                    </div>
                    </div>
                    </label>

                    <div className="divider w-72 -mt-10"></div> 
                    <label className='-mt-5 -mb-5'> 
                    <div className="collapse collapse-plus text-sm -ml-4 -mt-3 ">
                    
                    <input type="checkbox" /> 
                    <div className="collapse-title text-sm w-80 hover:text-yellow-100 hover:animate-pulse -mt-5">
                        Interests
                    </div>
                    <div className="collapse-content -mt-5"> 
                        <p className=' mb-10 max-w-lg'>
                        My favorite topics are mathematics, artificial intelligence, computer hardware and software. I love building my own desktop, 
                        assembling my mechanical keyboards, and learning programming languages. 
                        {/* <p className=' mt-5 max-w-lg'>My goal is to creatively solve our community challenges with the advantages of technology. </p> */}
                        
                        </p>
                    </div>
                    </div>
                    </label>

                    <div className="divider w-72 -mt-10"></div> 
                    <label className='-mt-5 -mb-5'> 
                    <div className="collapse collapse-plus text-sm -ml-4 -mt-3 ">
                    
                    <input type="checkbox" /> 
                    <div className="collapse-title text-sm w-80  hover:text-yellow-100 hover:animate-pulse -mt-5">
                        More about me?
                    </div>
                    <div className="collapse-content -mt-5"> 
                        <p className=' mb-10 max-w-lg'>
                        I am addicted to coffee and coding, enjoy playing soccer with my friends, and consider myself a mechanical keyboard enthusiast. I am always happy to connect with you. Stay connected and happy clacking! 😄
                        </p>
                    </div>
                    </div>
                    </label>
                    
                    <div className='mb-10'><DownloadButton/></div>
                    {/* <div className="divider w-72 -mt-10"></div>  */}

                    
                </div>
                </FadeInSection>
            </div>
            
        </div>
        

    </div>
        
    );
};

const Audio = () => <a href="https://www.howtopronounce.com/quoc/5104800" target="_blank" rel="noopener noreferrer"><GoUnmute size='16' className='ml-2 mt-2' /> </a>;
    
export default Intro;
