import * as ALL from '../PagesContent/'
import TopNav from '../TopNavBar/TopNavBar.js';
import Footer from '../Footer/Footer.js';
import { FaGithubAlt,FaCode } from 'react-icons/fa';

const ContentContainer = (props) => {
  return (
    <div className="bg-custom-important">
      <div className='mb-[64px] bg-custom-important'>
          <TopNav className="bg-custom-important"/>
      </div>
      <div className='content-container'>
          {/* <div className='content-list'> */}
          
            {/* {props.content==="Introductions"?<ALL.Intro/>:null}
            {props.content==="Skills"?<ALL.Skills/>:null}
            {props.content==="Experience"?<ALL.Experience setContent={props.setContent}/>:null}
            {props.content==="Educations"?<ALL.Education/>:null}
            {props.content==="Certifications"?<ALL.Certifications/>:null} */}
            {/* <div><a href="https://dccc.edu"><img src={Logo} alt="logo" className='w-12 h-auto mt-8 ml-7 md:ml-20 -mb-10 z-40'></img></a></div> */}
            <SectionRef innerRef={props.introRef}/>
            <Divider className='header-custom-text-color'/>
            <div className="md:mt-1 md:mb-6">
            <ALL.Intro />
            {/* <DividerLine />  */}
            <div className="divider md:mx-80 mx-9 opacity-70 text-gray-300 bg-custom-important shadow-xs"><FaCode size={25}/><FaGithubAlt size={25}/><FaCode size={25}/></div>
            </div>

            {/* <div className='dark:bg-gray-600 bg-opacity-10'> */}
            <SectionRef innerRef={props.expRef} />
            <Divider sectionName={"$ ls Experience/"} />
            <div className='center-div mb-6'><ALL.Experience setContent={props.setContent} /></div> 
            {/* <div className="divider md:mx-80 mx-9 opacity-40 -mb-5 shadow-xs"><FaCode size={25}/><FaGithubAlt size={25}/><FaCode size={25}/></div> */}
            {/* </div> */}
            
            <SectionRef  innerRef={props.eduRef}/>
            <div className='center-div'><Divider sectionName={"$ ls Education/"}/></div>
            {/* <div className='z-1 -mb-[1500]'> <ALL.CyberpunkBackground/> </div> */}
            {/* <div className='mb-[30px] ml-0 flex flex-col lg:flex-row lg:ml-[0px] center-div'>
            <div className="relative top-10 ml-5 lg:-ml-[60px] lg:left-[150px]">
              <div className="w-52 h-[0.75px] bg-gradient-to-r from-yellow-200 to-transparent"></div>
              <div className="h-20 w-1 -ml-[1px] -mt-[6px] bg-gradient-to-b from-yellow-200 to-transparent"></div>
            </div> */}
            <div className='mt-0 pt-0 md:ml-0 md:mt-8'><ALL.Education/></div>
            {/* <div className="divider md:mx-80 mx-9 opacity-40 -mb-5 shadow-xs"><FaCode size={25}/><FaGithubAlt size={25}/><FaCode size={25}/></div> */}
            
            {/* <div className='custom-text-color dark:bg-gray-600 bg-opacity-10 mt-5'> */}
            {/* <Divider sectionName={"Certifications"} innerRef={props.certsRef}/> */}
            {/* <div className=''><ALL.Certifications/></div> */}
            {/* <div className="relative -bottom-[620px] lg:right-[120px]">
              <div className="h-20 w-[0.75px] bg-gradient-to-t from-yellow-200 to-transparent ml-auto"></div>
              <div className="w-52 h-[0.75px] bg-gradient-to-l from-yellow-200 to-transparent"></div>
            </div>
            
            </div> */}
        

            {/* <div className="divider md:mx-80 mx-9 opacity-40 -mb-5 shadow-xs"><FaCode size={25}/><FaGithubAlt size={25}/><FaCode size={25}/></div> */}
            {/* </div> */}
            {/* <SectionRef innerRef={props.curriculumRef}/>
            <Divider sectionName={"Expertise"} />
            <ALL.Curriculum /> */}
          
            {/* <div className="divider md:mx-80 mx-9 opacity-40 -mb-5 shadow-xs"><FaCode size={25}/><FaGithubAlt size={25}/><FaCode size={25}/></div> */}

            {/* <div className='custom-text-color dark:bg-gray-600 bg-opacity-10 mt-5'> */}
            <SectionRef innerRef={props.skillsRef}/>
            <Divider sectionName={"$ ls techSkills/"}/>
            <ALL.Skills/>
            <div className="mb-6"></div>
            
            {/* <div className="divider md:mx-80 mx-9 opacity-40 -mb-5 shadow-xs"><FaCode size={25}/><FaGithubAlt size={25}/><FaCode size={25}/></div> */}
            {/* </div> */}

            {/* <Divider sectionName={"Projects"} innerRef={props.projectsRef}/>
            <ALL.Projects/>
            <div className="divider md:mx-80 mx-9 opacity-40 shadow-xs -mt-12 mb-2 md:mt-0"><FaCode size={25}/><FaGithubAlt size={25}/><FaCode size={25}/></div>
            <div className="mb-6"></div>
          */}
          <div className="divider md:mx-80 mx-9 opacity-70 text-gray-300 shadow-xs mb-2 md:mt-0 bg-custom-important"><FaCode size={25}/><FaGithubAlt size={25}/><FaCode size={25}/></div>
      </div>
      
      
      <div className='bg-custom-important'>
        <Footer />
      </div>
    </div>
  );
};

const SectionRef = (props) => {
  return (
    <div ref={props.innerRef} className='important-inner-ref'></div>
  )
}

const Divider = (props) =>  {
  return (
  
  <div>
    <div  id={props.sectionName} className="font-semibold" >
      {/* <FaHashtag size={14} className="mt-1 mr-1 md:mt-2 md:mr-1"/> */}
      <h2 className="header-text">{props.sectionName}</h2> 
    </div>
  </div>
  )
}

// const DividerLine = (props) => {
//   const [time , setTime] = useState(0)
//   useEffect(() => {
//     const interval = setInterval(() => {
      
//       setTime(currentCount => {
//         return currentCount +1 
//       })
//     }, 2000);
    
//     return () => clearInterval(interval);
//   }, []);

//   return(
//     <div className='flex flex-row mt-3 -mb-5 text-xs'>
//     {/* <hr className={`sidebar-hr ${time%2===0?"translate-x-52":"-translate-x-72"}`} /> */}
//     <p className={`md:ml-52 ml-10 ` }> ... ... ... </p>
//     <p className={`md:ml-20 ml-10 `}> ... ... ... </p>
//     <p className={`md:ml-20 ml-10 `}> ... ... ... </p>
//     <p className={`md:ml-20 ml-10 `}> ... ... ... </p>
//     <p className={`md:ml-20 md:block hidden `}> ... ... ... </p>
//     <p className={`md:ml-20 md:block hidden `}> ... ... ... </p>
//     <p className={`md:ml-20 md:block hidden `}> ... ... ... </p>
//     <p className={`md:ml-20 md:block hidden `}> ... ... ... </p>
//     <p className={`md:ml-20 md:block hidden `}> ... ... ... </p>
//     <p className={`md:ml-20 md:block hidden `}> ... ... ... </p>
  
//     </div>
//   )}

export default ContentContainer;