import { FaRegSun } from "react-icons/fa";
// import {Card} from 'flowbite-react';
import HoverBoxes from "./FramerMotion";
import FadeInSection from "./FadeInSection";

const Skills = (props) => {
    
    return (
        <div className="content-wrapper mt-14 mb-5 center-div" ref={props.skillsRef}>

            <div className="mt-7">
                
                <div className="lg:-ml-[1000px] -mt-10 center-div mb-5">
                    <h3 className="bigger-size-text tracking-tight flex flex-row ">
                    Focus Areas
                    </h3>
                    
                    </div>
                </div>

                <div className="ml-0 mt-2 flex flex-row md:mb-5 text-center md:justify-start">
                
                    <div className="md:flex md:flex-row ml-5">

                        <div className="ml-5 md:ml-3 mb-5 md:mr-4">
                        <FadeInSection>
                            <h4 className="text-xs md:text-lg tracking-tight custom-text-color-purple dark:text-white flex flex-row  md:font-normal  mb-5">
                            <FaRegSun size={12} className="mr-1 md:mt-2"/>Front-end
                            </h4>
                            <div className="radial-progress bg-white bg-opacity-20 bg-gray-400 border-3 border-white dark:text-accent dark:border-white " style={{ "--value": "75", "--size": "4.5rem", "--thickness": "3.5px" }}>75%
                            </div>
                        </FadeInSection>
                        </div>

                        <div className="ml-5 md:ml-16">
                        <FadeInSection>
                            <h4 className="text-xs md:text-lg tracking-tight custom-text-color-purple dark:text-white flex flex-row  md:font-normal  mb-5">
                            <FaRegSun size={12} className="mr-1  md:mt-2"/>Back-end
                            </h4>
                            <div className="radial-progress bg-white bg-opacity-20 bg-gray-400 border-3 border-white dark:text-accent" style={{ "--value": "90", "--size": "4.5rem", "--thickness": "3.5px" }}>90%
                            </div>
                        </FadeInSection>
                        </div>
                    </div>

                    <div className="md:flex md:flex-row md:ml-1 ml-8">

                        <div className="ml-5 md:ml-16  mb-5">
                        <FadeInSection>
                            <h4 className="text-xs md:text-lg tracking-tight custom-text-color-purple dark:text-white flex flex-row  md:font-normal  mb-5">
                            <FaRegSun size={12} className="mr-1  md:mt-2"/>Cloud & DevOps
                            </h4>
                            <div className="radial-progress bg-white bg-opacity-20 bg-gray-400 border-3 border-white dark:text-accent" style={{ "--value": "90", "--size": "4.5rem", "--thickness": "3.5px"}}>90%
                            </div>
                        </FadeInSection>    
                        </div>

                        <div className="ml-5 md:ml-16">
                        <FadeInSection>
                            <h4 className="text-xs md:text-lg tracking-tight custom-text-color-purple dark:text-white flex flex-row  md:font-normal  mb-5">
                            <FaRegSun size={12} className="mr-1  md:mt-2"/>Data & AI/ML
                            </h4>
                            <div className="radial-progress bg-white bg-opacity-20 bg-gray-400 border-3 border-white dark:text-accent"  style={{ "--value": "85", "--size": "4.5rem", "--thickness": "3.5px" }}>85%
                            </div>
                        </FadeInSection>
                    </div>
                
                </div>
              
            </div>

            <div className="">
                <svg className="relative -left-10 md:left-0 md:top-0 h-full md:-mt-[150px] md:w-[970px] md:-ml-[120px] lg:-mb-[700px]" viewBox="0 0 120 100" preserveAspectRatio="none">
                    <defs>
                    
                        <linearGradient id="fadeStroke" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stop-color="#8b5cf6" stop-opacity="0.3" />
                            <stop offset="10%" stop-color="#8b5cf6" stop-opacity="1.1" />
                            <stop offset="90%" stop-color="#8b5cf6" stop-opacity="0.85" />
                            <stop offset="100%" stop-color="#8b5cf6" stop-opacity="0.25" />
                        </linearGradient>
                    </defs>
                    <polyline
                        points="10,90 10,20 110,20 110,-190"
                        stroke="url(#fadeStroke)"
                        strokeWidth="0.1"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="shadow-[0_0_30px_rgba(139,92,246,1)]"
                    />
                </svg>
                <div className="special-fly-from-bottom relative -left-[18px] -top-[170px] md:-left-[47px] md:top-[540px] transform -translate-y-1/2 w-4 h-4 bg-[#8b5cf6] rounded-full shadow-[0_0_20px_5px_rgba(255,255,255,0.4)]"></div>
                <div className="relative -right-[275px] -top-[280px] md:left-[762px] md:top-[15px] transform -translate-y-1/2 w-4 h-4 bg-[#8b5cf6] rounded-full shadow-[0_0_20px_5px_rgba(255,255,255,0.4)]"></div>
                <FadeInSection>   
                <div className="-mt-[190px] md:mt-0 lg:mt-[40px] lg:ml-0">
                    <HoverBoxes/>
                </div> 
                </FadeInSection>         
            </div>   
       
        </div>
    );
};
    
export default Skills;