import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function panel12Props(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  } else {
    return {
      id: `vertical-tab-${index}`
    };
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const experienceItems = {
    "Roku Inc.": {
      jobTitle: "Software Engineer @",
      duration: "May 2023 - Present",
      desc: [
        "Cloud Technology and Infrastructure Team.",
        "Hybrid - Boston, MA.",
        "Working on Developer Tools and Platform Infrastructure Engineering.",
      ],
      previousRoles: [
        {
          jobTitle: "Software Engineer Intern",
          duration: "June 2022 - September 2022",
          desc: [
            "Internship Experience.",
          ]
        }
      ]
    },
    "iMaPLe Lab": {
      jobTitle: "Research Assistant, AI/ML @",
      duration: "September 2022 - Present",
      desc: [
        "iMaPLe - Intelligent Machine Perception and Learning Lab.",
        "Remote - Philadelphia, PA.",
        "Researching Machine Learning for Audio, Acoustic, Speech, and Signal Processing."
      ]
    },
    "Moberg Analytics": {
      jobTitle: "Software Engineer @",
      duration: "September 2022 - June 2023",
      desc: [
        "CONNECT Application - Development Team, Full-stack.",
        "Hybrid - Philadelphia, PA.",
        "Worked on CONNECT, a cloud-based AI-powered platform for neurocritical care."
      ],
      previousRoles: [
        {
          jobTitle: "Software Engineer",
          duration: "March 2022 - June 2022",
          desc: [
            "Co-op Experience.",
          ]
        }
      ]
    },
    "Bristol Myers Squibb": {
      jobTitle: "Cloud Developer @",
      duration: "March 2021 - September 2021",
      desc: [
        "DevOps, Cloud Infrastructure and Engineering Team.",
        "Remote - Princeton, NJ.",
        "Co-op Experience.",
        "Developed a serverless, full-stack cloud-based application for a licensing management system."
      ]
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `${i}.` : key} {...panel12Props(i)} />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i} key={i}>
            <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
            </span>
            <span className="joblist-job-company">{key}</span>
            <div className="joblist-duration">
            {experienceItems[key]["duration"]}
            </div>
            <ul className="job-description">
            {experienceItems[key]["desc"].map((descItem, i) => (
                <FadeInSection delay={`${i + 1}00ms`} key={i}>
                <li>{descItem}</li>
                </FadeInSection>
            ))}
            </ul>
            {experienceItems[key]["previousRoles"] &&
            experienceItems[key]["previousRoles"].map((role, j) => (
                <div key={j}>
                <span className="joblist-job-title">
                    {role.jobTitle + " "}
                </span>
                <div className="joblist-duration">{role.duration}</div>
                <ul className="job-description">
                    {role.desc.map((descItem, k) => (
                    <FadeInSection delay={`${k + 1}00ms`} key={k}>
                        <li>{descItem}</li>
                    </FadeInSection>
                    ))}
                </ul>
                </div>
            ))}
        </TabPanel>
        ))}
    </div>
  );
};

export default JobList;
