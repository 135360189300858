import { FaBook } from "react-icons/fa";
import { Card } from 'flowbite-react';
import { useRef, useEffect } from 'react';
import "../../styles/Experience.css";
import FadeInSection from "./FadeInSection";

const Experience = (props) => {

    const dotRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const dot = dotRef.current;
            const rect = dot.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            if (rect.top < windowHeight / 1.7) {
                dot.classList.add('animate-drop');
            } else {
                dot.classList.remove('animate-drop');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (

        <div className="content-wrapper  bg-black relative" ref={props.curriculumRef}>
            {/* Vertical Glowing green Line */}
            <div className="absolute left-[27px] top-0 h-full w-1 bg-gradient-to-b from-green-500 to-transparent"></div>

            {/* Glowing green Dot */}
            <div ref={dotRef} className="absolute left-5 top-1/2 transform -translate-y-1/2 w-4 h-4 bg-green-500 rounded-full shadow-[0_0_20px_5px_rgba(255,255,255,0.4)]"></div>

            <div className="md:ml-[70px] ml-7 ">
                <Card className="mb-5 bg-custom-important box">
                    <FadeInSection>
                    <h4 className="text-lg tracking-tight text-white dark:text-white flex flex-row bg-black ">
                        <FaBook size={20} className="mr-1 mt-1 custom-text-color-teal" /> Degrees
                    </h4>
                    <p className="font-normal text-gray-200 dark:text-gray-400 bg-black edu-desc">
                        <ul className="list-disc list-inside pl-2">
                            <li><a href="https://drexel.edu" target="_blank" rel="noopener noreferrer" className=' hover:animate-pulse'>Master of Science in Computer Engineering, Drexel University, Philadelphia, PA, USA</a></li>
                            <li><a href="https://drexel.edu" target="_blank" rel="noopener noreferrer" className=' hover:animate-pulse'>Bachelor of Science in Computer Engineering, Drexel University, Philadelphia, PA, USA</a></li>
                            <li><a href="https://www.linkedin.com/school/lhpvietnam/?originalSubdomain=vn" target="_blank" rel="noopener noreferrer" className='hover:animate-pulse'> Highschool Diploma, Le Hong Phong Highschool for the Gifted, Ho Chi Minh City, Vietnam </a></li>
                        </ul>
                    </p>
                    </FadeInSection>
                </Card>

                <Card className="mb-5 box bg-custom-important">
                    <FadeInSection>
                    <h4 className="text-lg tracking-tight text-white dark:text-white flex flex-row ">
                        <FaBook size={20} className="mr-1 mt-1 custom-text-color-teal" /> Certifications
                    </h4>
                    <p className="font-normal text-gray-200 dark:text-gray-400 edu-desc">
                        <ul className="list-disc list-inside pl-2">
                            <li><a href="https://www.credly.com/badges/3d100d72-42d7-4076-93cb-6e286c8261fe/public_url" target="_blank" rel="noopener noreferrer" className='hover:animate-pulse text-beauty -pr-5'>Certified Terraform Associate, HashiCorp, Sep 2023</a></li>
                            <li><a href="https://www.credly.com/badges/0e9b1303-c3ac-42db-a3a3-6958f0e0157a/public_url" target="_blank" rel="noopener noreferrer" className=' hover:animate-pulse'>Certified Solutions Architect - Associate, AWS Training and Certification, Oct 2021</a></li>
                            <li><a href="https://www.credly.com/badges/1ab2225e-a8e4-416b-aa11-2711de4ee9b5/public_url" target="_blank" rel="noopener noreferrer" className='hover:animate-pulse'>Certified Cloud Practitioner, AWS Training and Certification, Jul 2021</a></li>
                            <li><a href="https://coursera.org/share/fab982da7e2144334aace52e4ec9d264" target="_blank" rel="noopener noreferrer" className='hover:animate-pulse'>AWS Fundamentals: Building Serverless Applications, Coursera E-learning, Sep 2020</a></li>
                            <li><a href="https://university.atlassian.com/student/award/DgtVkwp7ucGPrwqRNZRjKCBY" target="_blank" rel="noopener noreferrer" className='hover:animate-pulse'>Jira Fundamentals Badge, Atlassian University, Mar 2022</a></li>
                        </ul>  
                    </p>
                    </FadeInSection>
                </Card>
            </div>
        </div>
        
    );
};

export default Experience;
